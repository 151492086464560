import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../app.service';
declare let $: any;

@Component({
  selector: 'app-cutomer-search',
  templateUrl: './cutomer-search.component.html',
  styleUrls: ['./cutomer-search.component.scss']
})
export class CutomerSearchComponent implements OnInit {

  Row = [];
  constructor(public appservice: AppService,private router: Router) {

  
    $(".itext").focus();
 

    }




  get_ledger() {
    try {
      //this.appservice.get_Ledger_Master();
     // 

     this.appservice.SLedger_Rows=[];
     if(this.appservice.S_Area=='All')
     {
       
     this.appservice.SLedger_Rows=this.appservice.Ledger_Master_Rows;
     }
     else
     {
       
       this.appservice.SLedger_Rows= this.appservice.Ledger_Master_Rows.filter(e => e.Area.toLowerCase().includes(this.appservice.S_Area.toLocaleLowerCase())); 
     }
     this.appservice.SLedger_Rows = this.appservice.SLedger_Rows.filter(e => e.Group_ID == this.appservice.Ledger_Type || e.Group_ID == "3");

     
      
    } catch { }
  }

  get_Search_Patient(data)
  {

    this.appservice.Customer_Master_Rows=[];
    
    this.appservice.get("Api/Invoice/get_Search_Customer?Search_Name="+data).subscribe((res: any) => {
    this.appservice.Customer_Master_Rows = JSON.parse(res).record;
    

    });
  
  }
   t_search = "";
  addReset()
  {
    
  this.appservice.Patient_Name=true;
  return this.router.navigate(['/master/ledger-entry']);
  }
  
  select_Ledger(rowData) {
    this.appservice.from_customer_page=true;
    this.appservice.Selected_Customer = rowData;
    // this.appservice.load_page('/report/customer-ledger');
    this.appservice.back();


  }

  selected_row1(row_No, dt) {


    var len = 0;
    try {
      len = dt.filteredValue.length;

    } catch { }


    if (len == 0) {
      this.select_Ledger(dt.value[row_No]);
      
    }
    else {
      this.select_Ledger(dt.filteredValue[row_No]);
      
    }
    
  }


  row_No = -1;

  up() {
    if (this.row_No >= 0) {
      this.row_No = this.row_No - 1;
    }
  }

  down() {
    this.row_No = this.row_No + 1;
  }


  ngOnInit(): void {
    $(".itext").focus();
  }

}
