import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  isload:boolean=false;
  data :any={}
  constructor(public http: HttpClient,public appservice: AppService,private toastr: ToastrService, public router: Router) {
 
    this.appservice.Page_From="login";
    this.get_load();
  }

get_load()
{
  if (this.getData()) {

    this.isload = true;

    var user = this.appservice.decryptData(localStorage.getItem('U_Key'));
    var pwd = this.appservice.decryptData(localStorage.getItem('P_Key'));

    this.router.navigate(['/loading']);
    this.appservice.get("Api/Common/get_Emp_Login?Username=" + user + "&Password=" + pwd).subscribe((res: any) => {
      this.isload = false;
      if (res != '') {
        this.appservice.Current_User = JSON.parse(res).record[0];
        this.appservice.Company = "_" + this.appservice.Current_User.UM_Company;
        this.appservice.Rights_Name = this.appservice.Current_User.Rights;
        this.data = this.appservice.Current_User;
        this.appservice.get_Token();
      } else {
        localStorage.clear();
        this.appservice.Company = "";
        this.router.navigate(['/'], { replaceUrl: true });
      }
    });




  }

}

get_Password()
{
  return this.router.navigate(['/forgot-new-password']);
 
}

getData() {
    return localStorage.getItem('U_Key');
  }

  public btndisable:boolean=false;
  public addD: any = {};
  rows = [];

  login(f) {

    this.btndisable=true;
    this.appservice.get("Api/Common/get_Emp_Login?Username=" + f.form.value.Username + "&Password=" + f.form.value.Password).subscribe((res: any) => {

      
      this.btndisable=false;

      if (res != "") {
        this.error_ = "";
        var User = this.appservice.encryptData(this.addD.Username);
        var Pwd = this.appservice.encryptData(this.addD.Password);
       localStorage.setItem('U_Key', User);
        localStorage.setItem('P_Key', Pwd);

        this.get_load();
        


      }
      else
      {

        this.error_="Invalid User Name and Password";
        this.toastr.error("Invalid User Name and Password", "Error", { timeOut: 3000 });
        
      }

    });
  }
  error_="";

  ngOnInit() {
  }

}
