import { Component } from '@angular/core';

@Component({
  selector: 'app-load-page',
  standalone: true,
  imports: [],
  templateUrl: './load-page.component.html',
  styleUrl: './load-page.component.scss'
})
export class LoadPageComponent {

}
