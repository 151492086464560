<div class="col-md-12"  
style=" background-color: white; 
display:inline-flex; position: fixed; top: 0px;left: 0px; padding-left: 48%; padding-top: 250px; height: 100vh; width: 100% ; z-index: 9999999; bottom:0px; text-align: center;">
    
<div class="d-flex justify-content-center">
    <div class="spinner-border text-primary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</div>

</div>