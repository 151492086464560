<!-- Start Login Area -->

<div class="col-md-12"  style=" background-color: #f4f4f4; display:inline-flex; position: fixed; top: 0px;left: 0px; width: 100% ; z-index: 9999999; bottom:0px;">
    

    <div class="spin_block" *ngIf="isload"   > 

        <div class="d-flex justify-content-center">
          <div class="spinner-border text-primary" role="status">
              <span class="sr-only">Loading...</span>
          </div>
      </div>

       </div>
       
    <div class="col-md-6 mhide " style="padding: 40px;" >
        <img src="../../../assets/img/Lab.png">
    </div>
    <div class="col-md-6 " style="background-color: white; " >

 
 
            <div style="max-width: 300px; padding-top: 50px;margin: auto;">


                <div style="text-align: center;">
                  <img style="height: 50px;" src="assets/img/Chef.png" alt="image">
                  <div style="font-size: 16px;
                  text-align: center;
                  line-height: 1.45;
                  color: #455a64;
                  padding-top: 15px;
                  text-transform: uppercase;
                  font-weight: 600;">Laboratory Management </div>





                     
                
<br>
              
            </div>

                <div style="font-size: 1.5rem;
                text-align: center;
                line-height: 1.45;
                color: #6e6b7b;
                margin: 16px 0;
                font-weight: 600;">Reset your password</div>
                    <div class="form-group">
                       <label> Email ID  </label>
                        <input type="text" style="background-color: #f8f9fa0d; border-radius: 5px!important;" class="form-control" [(ngModel)]="Email_ID"   name="Email_ID1" #Email_ID1="ngModel"  >
                        
                    </div>

                

                  
                    <div class="form-group">
                        <span class="star" style="color: #fb2105;text-align: center;">{{error_}}</span>
                        <span class="star" style="color: #2be709;text-align: center;">{{error_Msg}}</span>

                    </div>


                   

                    <button type="submit" style="width: 100%; background-color: #5d498e;
                    border-color: #7e57c2;"   [disabled]="btndisable" class="btn btn-info"(click)="login()">
                        <span *ngIf="btndisable==true" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

                        Send Password</button>
                       
                        <div class="form-group" style="text-align: center;">
                            <br>
                           <Span style="font-size:16px;color:#958b9e5b;font-weight: 500;">Already Signed up ? </Span> <a (click)="get_Login()" style="font-size:16px;color:#083c8b"> Log in here !</a>
                            
                        </div>
                      

               
            </div>
        
</div>
</div>
<!-- End Login Area -->