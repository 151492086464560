import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/app.service';

import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import {Subject, Observable} from 'rxjs';
import { last } from 'rxjs/operators';


declare const $: any;
@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit {

  add: any = {};
  public btndisable: boolean = false;


  Street_Row = [];

  get_Street_Row() {
    this.Street_Row = this.appservice.get_street(this.add.Area);
  }

  constructor(public appservice: AppService, private toastr: ToastrService, private http: HttpClient) {


    if (this.appservice.isEdit) {
      this.add = appservice.Edit_Row;
      this.add.Join_Date = appservice.datefromat(this.appservice.Edit_Row.Join_Date);
      try {
        this.get_Street_Row();
      } catch{ }
    }
    else {
      this.clear();
      this.add.ID = "0";
    }


  }

  clear() {

    this.appservice.get_fields_of('Ledger_Master').forEach((activity) => {
      if (activity.Default_Value == "T_Date") {
        this.add[activity.Field] = this.appservice.T_Date;
      }
      else {
        this.add[activity.Field] = activity.Default_Value;
      }
    });
  }
  Valid;

  addData(f) {




    this.Valid = true;
    this.appservice.get_fields_of('Ledger_Master').filter(e => String(e.Validate).toLocaleLowerCase() == "true").forEach((data) => {

      if (this.add[data.Field] == "") {
        this.Valid = false;
        return;
      }

    });

    this.add.Company = this.appservice.Company;
    this.add.Company_ID = this.appservice.Company;
    this.add.Created_by = this.appservice.CREATED_BY;
    this.add.Table_Name = "Ledger_Master";
    if (this.Valid) {
      this.btndisable = true;
      this.appservice.headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8' });

      this.http.post(this.appservice.Server_URL + 'api/Master/Post_Ledger', this.add, { headers: this.appservice.headers })
        .subscribe(
          (val: string) => {
            this.btndisable = false;

            if (val == "True") {
              this.toastr.success("Details Saved Success", 'Msg');
              this.appservice.isEdit = false;
              this.appservice.get_Ledger_Master();
              
              this.appservice.back();
            }
            else {
              this.toastr.error(val, "Error", { timeOut: 3000 });
            }
          },
          response => {
            this.toastr.error('Error ', response, {
              timeOut: 3000
            });

          });

    }
  }
  ngOnInit(): void {
  }

}
